import React, { useState } from "react";
import "./App.css";

const EventCard = ({
  title,
  daytitle,
  date,
  location,
  registrationLink,
  registration,
  summary,
  fullDescription,
  isPastEvent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Truncate summary to roughly 5-6 lines (assuming average line length)
  const truncatedSummary =
    summary.length > 300 ? summary.slice(0, 300) + "..." : summary;

  return (
    <div className={`event-card ${isExpanded ? "expanded" : ""}`}>
      <div className="event-date">
        <span className="day">{date.getDate()}</span>
        <span className="month">
          {date.toLocaleString("default", { month: "short" })}
        </span>
        <>{daytitle}</>
      </div>
      <div className="event-details">
        <h3>{title}</h3>

        <p className="event-location">{location}</p>
        <div className="event-summary">
          <div
            dangerouslySetInnerHTML={{
              __html: isExpanded ? summary : truncatedSummary,
            }}
          />
          {summary.length > 300 && (
            <button onClick={toggleExpand} className="read-more-btn">
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
        {isExpanded && (
          <div className="event-full-description">
            <div>{fullDescription}</div>
          </div>
        )}
        <div className="event-actions">
          {!isPastEvent && (
            <a
              href={registrationLink}
              target="_blank"
              rel="noopener noreferrer"
              className="event-link"
            >
              {registration ? "Register" : "Learn More →"}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

function Events() {
  const upcomingEvents = [
    {
      title: "Preparing Data for GenAI and Introduction to LangChain",
      date: new Date(2024, 7, 2), // August 22, 2024
      daytitle: "Friday",
      location: "Location TBD",
      registrationLink: "https://forms.gle/gSg93n5pN1CZTXEr5",
      registration: true,
      summary:
        "<span style='font-weight: 700'>Nirav Kothari</span> will delve into the intricacies of data preparation for generative AI models and <span style='font-weight: 700'>Dharmesh Vaya</span> will introduce LangChain, a powerful tool for constructing language model applications",
      fullDescription:
        "Nirav Kothari will delve into the intricacies of data preparation for generative AI models and Dharmesh Vaya will introduce LangChain, a powerful tool for constructing language model applications",
    },
    {
      title: "Save the Date. Topic coming soon to your inbox",
      date: new Date(2024, 7, 23), // August 22, 2024
      daytitle: "Friday",
      location: "Location TBD",
      registrationLink: "/join",
      registration: false,
      summary: "Save the Date. Topic coming soon to your inbox",
      fullDescription:
        "Join us for an intensive workshop on Generative AI, where leading experts will guide you through the journey from theoretical concepts to real-world implementation. This hands-on session will cover the latest advancements in GenAI, practical use cases, and step-by-step guidance on building your own GenAI applications. Whether you're a beginner or an experienced practitioner, this workshop will provide valuable insights and skills to advance your GenAI journey.",
    },
  ];

  const pastEvents = [
    {
      title: "Introduction to Machine Learning",
      date: new Date(2024, 6, 15), // June 15, 2024
      daytitle: "Monday",
      location: "Zone Startup India",
      registrationLink: "#",
      summary:
        "Raj Desai  talks about Machine Learning vs. Deep Learning and Nimesh Mehta guides you to finding right product market fit for ML driven products",
      fullDescription:
        "This symposium brought together ethicists, AI researchers, and industry leaders to discuss the crucial topic of AI ethics. Speakers addressed issues such as bias in AI systems, privacy concerns, and the societal impact of AI. The event featured panel discussions, case studies, and interactive sessions, providing attendees with a comprehensive understanding of the ethical challenges in AI and strategies to address them.",
    },
    // Add more past events as needed
  ];

  return (
    <main className="App-main events-page">
      <section className="events-intro">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> MaLAI Club Events{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <p className="events-description">
            At MaLAI Club, we offer a diverse range of events to cater to all
            aspects of the ML and AI community:
          </p>
          <div className="events-features">
            <div className="feature">
              <h3>Meetups and Events</h3>
              <p>
                Regular meetups and events featuring talks, workshops, and
                networking opportunities.
              </p>
            </div>
            <div className="feature">
              <h3>Knowledge Sharing</h3>
              <p>
                A platform for sharing the latest research, trends, and
                developments in Data, ML, AI, Gen AI.
              </p>
            </div>
            <div className="feature">
              <h3>Collaboration Opportunities</h3>
              <p>
                Connect with like-minded individuals for projects, research, and
                professional growth.
              </p>
            </div>
            <div className="feature">
              <h3>Community Support</h3>
              <p>
                A supportive environment to ask questions, seek advice, and
                share experiences for developers.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="upcoming-events">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> Upcoming Events{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <div className="events-grid">
            {upcomingEvents.map((event, index) => (
              <EventCard key={index} {...event} isPastEvent={false} />
            ))}
          </div>
        </div>
      </section>

      <section className="past-events">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> Past Events{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <div className="events-grid">
            {pastEvents.map((event, index) => (
              <EventCard key={index} {...event} isPastEvent={true} />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}

export default Events;
