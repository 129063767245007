import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./App.css";
import contactImage from "./images/contact-image.jpg"; // Make sure to add this image to your project
import swal from "sweetalert";
import emailjs from "@emailjs/browser";
import { ReCAPTCHA } from "react-google-recaptcha";

function ContactUs() {
  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // const [formSent, setFormSent] = useState(false);

  const handleInput = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input.email === "" || input.message === "") {
      swal("Error", "Please fill all the required fields", "error");
      return;
    }

    const serviceId = "service_y13isxw";
    const templateId = "template_2n7bgvt";
    const publicKey = "CGjNxr3LD4qPkBxoz";

    const templateParams = {
      from_name: input.name,
      to_name: "Sreeraman",
      from_email: input.email,
      from_phone: input.phone,
      message: input.message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully", response);
        swal(
          "Form Submitted Successfully.",
          "We will get back to you ASAP.",
          "success"
        );
        // setFormSent(true);
        setInput({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log("Error sending email", error);
      });
  };

  //Recaptcha

  const captchaRef = useRef(null);

  console.log("Site Key:", process.env.REACT_APP_SITE_KEY);

  return (
    <main className="App-main contact-us-page">
      <section className="contact-us-intro">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> Contact Us{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <div className="contact-us-content">
            <div className="contact-us-text">
              <h3>About MaLAI Club</h3>
              <p>
                MaLAI Club is Mumbai's premier community for Machine Learning
                and Artificial Intelligence enthusiasts. Founded in July 2017,
                we bring together professionals, students, and hobbyists to
                explore the cutting-edge of AI and ML technologies.
              </p>
              <img
                src={contactImage}
                alt="MaLAI Club Community"
                className="contact-us-image"
              />
              <h3>Why Contact Us?</h3>
              <p>
                We're here to assist you with any questions about our events,
                membership, or collaborations. Whether you're seeking
                information or want to contribute to our community, we're eager
                to hear from you.
              </p>
              <h3>Get in Touch</h3>
              <p>
                For general inquiries, please email us at:{" "}
                <a href="mailto:gbgmum@gmail.com">gbgmum@gmail.com</a>
              </p>
              <p>
                If you're interested in becoming a member, please visit our{" "}
                <Link to="/join">Join Us</Link> page to sign up.
              </p>
            </div>
            <div className="contact-us-form">
              <h3>Quick Contact Form</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={input.name}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={input.email}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Phone No.</label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={input.phone}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={input.message}
                    onChange={handleInput}
                    rows="4"
                    required
                  ></textarea>
                </div>
                <div>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={captchaRef}
                  />
                </div>
                <button type="submit" className="cta-button">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="social-follow">
            <a
              href="https://x.com/malaiclub"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter-follow-button"
            >
              Follow @malaiclub on Twitter
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ContactUs;
