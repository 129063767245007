import React from "react";
import "./App.css";

// Import placeholder images (you should replace these with actual images)
import sreeramanImage from "./images/sreeraman.jpg";
import rushabhImage from "./images/rushabh.jpg";
import dharmeshImage from "./images/dharmesh.jpg";
import teamImage from "./images/team.jpg"; // Add a team image

const OrganiserCard = ({ name, image, bio, twitter }) => (
  <div className="organiser-card">
    <img src={image} alt={name} className="organiser-image" />
    <div className="organiser-info">
      <h3>{name}</h3>
      <p>{bio}</p>
      <a
        href={`https://twitter.com/${twitter}`}
        target="_blank"
        rel="noopener noreferrer"
        className="twitter-follow-button"
      >
        Follow @{twitter}
      </a>
    </div>
  </div>
);

function Organisers() {
  const organisers = [
    {
      name: "Sreeraman Thiagarajan",
      image: sreeramanImage,
      bio: "CEO of Agrahyah, an AI Solutions Company. Mentor at Google For Startups. Formerly GDE for Growth and founder of GBG Mumbai",
      twitter: "sreeraman",
    },
    {
      name: "Rushabh Vasa",
      image: rushabhImage,
      bio: "CTO of Agrahyah. Google Developer Expert for Cloud, Founding member of GBG Mumbai. Mentor at Google For Startups.",
      twitter: "rushvasa",
    },
    {
      name: "Dharmesh Vaya",
      image: dharmeshImage,
      bio: "Google Developer Expert for Cloud, GDGC Lead Mumbai. Solutions Architect: Prisma Cloud at Palo Alto Networks",
      twitter: "DRVaya",
    },
  ];

  return (
    <main className="App-main organisers-page">
      <section className="team-intro">
        <div className="container">
          <div className="team-content">
            <div className="team-image">
              <img src={teamImage} alt="MaLAI Club Team" />
            </div>
            <div className="team-text">
              <h2 className="section-title">
                <span className="highlight">&lt;</span> Our Organisers{" "}
                <span className="highlight">/&gt;</span>
              </h2>
              <p>
                Meet the passionate minds behind MaLAI Club. Our diverse team of
                experts in Machine Learning, Artificial Intelligence, and
                community building is dedicated to fostering innovation and
                knowledge sharing in Mumbai's tech ecosystem. Together, we're
                shaping the future of AI and ML in our city.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="organisers-section">
        <div className="container">
          <div className="organisers-grid">
            {organisers.map((organiser, index) => (
              <OrganiserCard key={index} {...organiser} />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}

export default Organisers;
