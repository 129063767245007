import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import communityImage from "./images/malai-community.jpg";
import Events from "./Events";
import Organisers from "./Organisers";
import JoinUs from "./JoinUs";
import ContactUs from "./ContactUs"; // Import the new ContactUs component
import { Autoplay } from "swiper/modules";
import { tr } from "date-fns/locale";

// Separate Home component
function Home() {
  return (
    <main className="App-main">
      <section id="hero" className="hero-section">
        <div className="container">
          <h1 className="main-title">MaLAI Club</h1>
          <p className="subtitle">
            The Cream of ML and AI Enthusiasts in Mumbai
          </p>
          <div className="hero-cta">
            <Link to="/join" className="cta-button">
              Join Us
            </Link>
            <a href="#about" className="cta-button secondary">
              Learn More
            </a>
          </div>
        </div>
      </section>

      <section id="about" className="about-section">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> Who We Are{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <div className="about-content">
            <div className="about-text">
              <p>
                Our club stands for the 'Machine Learning and Artificial
                Intelligence Club,' dedicated to creating a vibrant community
                for ML and AI enthusiasts in Mumbai.
              </p>
              <p>
                Founded in July 2017, we were way too early in assimilating ML
                and AI enthusiasts. Now we are back!
              </p>
              <h3>Our Mission</h3>
              <p>
                We aim to bring together the best minds working in ML and
                AI—enthusiasts, developers, and practitioners—under one roof.
                Our mission is to foster collaboration, share knowledge, and
                drive innovation in the field of machine learning and artificial
                intelligence.
              </p>
            </div>
            <div className="about-image">
              <img src={communityImage} alt="MaLAI Club Community" />
            </div>
          </div>
        </div>
      </section>

      <section id="offer" className="offer-section">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> What We Offer{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <div className="offer-grid">
            <div className="offer-item offer-item-1">
              <i className="fas fa-users"></i>
              <h3>Meetups and Events</h3>
              <p>
                Regular meetups and events featuring talks, workshops, and
                networking opportunities.
              </p>
            </div>
            <div className="offer-item offer-item-2">
              <i className="fas fa-brain"></i>
              <h3>Knowledge Sharing</h3>
              <p>
                A platform for sharing the latest research, trends, and
                developments in Data, ML, AI, Gen AI.
              </p>
            </div>
            <div className="offer-item offer-item-3">
              <i className="fas fa-project-diagram"></i>
              <h3>Collaboration Opportunities</h3>
              <p>
                Connect with like-minded individuals for projects, research, and
                professional growth.
              </p>
            </div>
            <div className="offer-item offer-item-4">
              <i className="fas fa-hands-helping"></i>
              <h3>Community Support</h3>
              <p>
                A supportive environment to ask questions, seek advice, and
                share experiences for developers.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="join" className="join-section">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> Join Us{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <p>
            Whether you're a seasoned professional or just starting your journey
            in ML and AI, MaLAI Club is the place for you. Join us to learn,
            share, and grow together in the exciting field of machine learning
            and artificial intelligence.
          </p>
          <div className="cta-container">
            <Link to="/join" className="cta-button">
              Become a Member (Free)
            </Link>
          </div>
        </div>
      </section>

      <section id="twitter-follow" className="twitter-follow-section">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> Follow Us{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <p>
            Stay updated with our latest events and discussions. Join our
            community on Twitter!
          </p>
          <a
            href="https://twitter.com/malaiclub"
            target="_blank"
            rel="noopener noreferrer"
            className="twitter-follow-button"
          >
            Follow @malaiclub on Twitter
          </a>
        </div>
      </section>

      <section id="events" className="events-section">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> Upcoming Events{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <div className="event-grid">
            <div className="event-card">
              <div className="event-date">
                <span className="day">2</span>
                <span className="month">AUG</span>
                <span className="month">Friday</span>
              </div>
              <div className="event-details">
                <h3>Preparing Data for GenAI and Introduction to LangChain</h3>
                <p>
                  <b>Nirav Kothari</b> will delve into the intricacies of data
                  preparation for generative AI models and <b>Dharmesh Vaya</b>{" "}
                  will introduce LangChain, a powerful tool for constructing
                  language model applications
                </p>
                <Link
                  to="https://forms.gle/gSg93n5pN1CZTXEr5"
                  target="_blank"
                  className="event-link"
                >
                  Register
                </Link>
              </div>
            </div>
            <div className="event-card">
              <div className="event-date">
                <span className="day">23</span>
                <span className="month">AUG</span>
                <span className="month">Friday</span>
              </div>
              <div className="event-details">
                <h3>Save the Date. Topic coming soon to your inbox</h3>
                <p>
                  If you have joined us or registered for any previous event,
                  you’ll be notified on all upcoming meetups!
                </p>
                <a href="/join" target="_blank" className="event-link">
                  Learn More &rarr;
                </a>
              </div>
            </div>
            {/* <div className="event-card">
              <div className="event-date">
                <span className="day">05</span>
                <span className="month">SEP</span>
                <span className="month">Thursday</span>
              </div>
              <div className="event-details">
                <h3>ML for Climate Change: Mumbai Hackathon</h3>
                <p>48-hour challenge to develop ML solutions addressing local climate issues.</p>
                  <Link to="/join" className='event-link'>Register</Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </main>
  );
}

function App() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleOutsideClick = (e) => {
    if (!navRef.current.contains(e.target)) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header" ref={navRef}>
          <div className="container">
            <nav className="App-nav">
              <Link to="/">
                <div className="logo">
                  <span className="logo-text">
                    Ma<span className="highlight">LAI</span> Club
                  </span>
                  <span className="logo-subtext">&#60;ML + AI /&#62;</span>
                </div>
              </Link>
              <button
                className={`nav-toggle ${isNavOpen ? "active" : ""}`}
                onClick={toggleNav}
              >
                <span className="hamburger"></span>
              </button>
              <ul className={`nav-menu ${isNavOpen ? "active" : ""}`}>
                <li>
                  <Link to="/" onClick={toggleNav}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/join" onClick={toggleNav}>
                    Join
                  </Link>
                </li>
                <li>
                  <Link to="/organisers" onClick={toggleNav}>
                    Organisers
                  </Link>
                </li>
                <li>
                  <Link to="/events" onClick={toggleNav}>
                    Events
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toggleNav}>
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/organisers" element={<Organisers />} />
          <Route path="/events" element={<Events />} />
          <Route path="/join" element={<JoinUs />} />
          <Route path="/contact" element={<ContactUs />} />{" "}
          {/* Add this new route */}
        </Routes>

        {/* ... (keep the existing footer code) */}
      </div>
    </Router>
  );
}

export default App;
