import React, { useState } from "react";
import "./App.css";
import joinUsImage from "./images/join-us-image.jpg";

function JoinUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    occupation: "",
    interests: [],
    experience: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        interests: checked
          ? [...prevState.interests, value]
          : prevState.interests.filter((interest) => interest !== value),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the data to your server or directly to Google Forms
    console.log(formData);
    // For demonstration, we'll just log the data
    alert("Form submitted successfully!");
  };

  return (
    <main className="App-main join-us-page">
      <section className="join-us-intro">
        <div className="container">
          <h2 className="section-title">
            <span className="highlight">&lt;</span> Join MaLAI Club{" "}
            <span className="highlight">/&gt;</span>
          </h2>
          <div className="join-us-content">
            <div className="join-us-text">
              <p className="join-us-description">
                MaLAI Club is Mumbai's premier community for ML and AI
                enthusiasts. We bring together professionals, students, and
                hobbyists to explore the cutting-edge of artificial intelligence
                and machine learning.
              </p>
              <img
                src={joinUsImage}
                alt="MaLAI Club Community"
                className="join-us-image"
              />
              <h3>Free Membership</h3>
              <p>
                Joining MaLAI Club is completely free. We believe in making
                knowledge and networking accessible to all who are passionate
                about ML and AI.
              </p>
              <h3>Why Join Us?</h3>
              <p>
                By joining MaLAI Club, you'll gain access to expert talks,
                workshops, networking events, and collaborative projects. It's
                your gateway to the thriving ML and AI ecosystem in Mumbai.
              </p>
            </div>
            <div className="join-form-container">
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSf5kFXkq3fYQeskVb-p3FoqZEny5BFODoMKc6peFYDcX1wMYQ/viewform?embedded=true"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >
                Loading…
              </iframe>
            </div>

            {/* <div className="join-us-form">
              <h3>Become a Member</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation">Occupation</label>
                  <input
                    type="text"
                    id="occupation"
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Interests (Select all that apply)</label>
                  <div className="checkbox-group">
                    {['Machine Learning', 'Artificial Intelligence', 'Deep Learning', 'Natural Language Processing', 'Computer Vision'].map((interest) => (
                      <label key={interest}>
                        <input
                          type="checkbox"
                          name="interests"
                          value={interest}
                          checked={formData.interests.includes(interest)}
                          onChange={handleChange}
                        />
                        {interest}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="experience">Experience Level</label>
                  <select
                    id="experience"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select your experience level</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                    <option value="Expert">Expert</option>
                  </select>
                </div>
                <button type="submit" className="cta-button">Join Now</button>
              </form>
            </div> */}
          </div>
          <div className="social-follow">
            <a
              href="https://x.com/malaiclub"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter-follow-button"
            >
              Follow @malaiclub on Twitter
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JoinUs;
